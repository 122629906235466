import get from 'lodash/get';
import { push } from 'connected-react-router';
import Cookies from 'js-cookie';
import { decode } from 'jsonwebtoken';
import moment from 'moment';
import { LOGIN_ERROR, USER_DETAILS, LOGOUT, MFA_VERIFICATION_ERROR, DOMAIN_NAME, IS_LOCALHOST } from '../constants/index';
import { apiAction, logEntry } from '../utils/index';
import { restoreChartConfig } from './charts';
import { setIsLoading } from './ui';
import { USER_MANAGEMENT, USER_LOGIN, USER_LOGOUT, UPDATE_USER_PASSWORD_FIRST_LOGIN, USER_MFA } from '../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setLoginError = (value, errorMessage) => ({
  type: LOGIN_ERROR,
  payload: { value, errorMessage }
});

export const setUserDetails = user => ({
  type: USER_DETAILS,
  payload: { user }
});

export const logoutUser = () => ({
  type: LOGOUT,
  payload: {}
});

const setUserAuthData = ({
  AccessToken,
  RefreshToken,
  UserName,
  UserId,
  Name,
  UserProfiles,
  Organisations,
  Session,
  CurrentOrganisationId
}) => {
  if (AccessToken)
    Cookies.set(`access-${envName}`, AccessToken, {
      expires: 7,
      domain: DOMAIN_NAME,
      secure: !IS_LOCALHOST
    });

  if (RefreshToken) Cookies.set(`refresh-${envName}`, RefreshToken, { expires: 7, domain: DOMAIN_NAME, secure: !IS_LOCALHOST });
  if (UserName) Cookies.set(`username-${envName}`, UserName, { expires: 7, domain: DOMAIN_NAME, secure: !IS_LOCALHOST });
  if (UserId) Cookies.set(`userid-${envName}`, UserId, { expires: 7, domain: DOMAIN_NAME, secure: !IS_LOCALHOST });
  if (Name) Cookies.set(`name-${envName}`, Name, { expires: 7, domain: DOMAIN_NAME, secure: !IS_LOCALHOST });
  if (Session) {
    Cookies.set(`session-${envName}`, Session, { expires: 1, domain: DOMAIN_NAME, secure: !IS_LOCALHOST });
  } else {
    Cookies.remove(`session-${envName}`, { domain: DOMAIN_NAME, secure: !IS_LOCALHOST });
  }
  if (UserProfiles) {
    //Cookies.set(`profiles-${envName}`, UserProfiles, { expires: 7, domain: DOMAIN_NAME, secure: !IS_LOCALHOST });
    localStorage.setItem(`profiles-${envName}`, JSON.stringify(UserProfiles));
    setSelectedProfile(UserProfiles);
  }

  if (Organisations) {
    setOrganisationData(Organisations);
    setSelectedOrganisation(Organisations, CurrentOrganisationId);
  }
};

export const setOrganisationData = Organisations => {
  let organisationsLogos = Organisations.map(item => {
    let orgId = item.OrganisationId;
    let orgLogo = item.OrganisationLogo;
    item.OrganisationLogo = null;
    return {
      OrganisationId: orgId,
      OrganisationLogo: orgLogo
    };
  });
  localStorage.setItem(`organisationslogos-${envName}`, JSON.stringify(organisationsLogos));
  localStorage.setItem(`organisations-${envName}`, JSON.stringify(Organisations));
  // Cookies.set(`organisations-${envName}`, Organisations, {
  //   expires: 7,
  //   domain: DOMAIN_NAME,
  //   secure: !IS_LOCALHOST
  // });
};

const setSelectedProfile = userProfiles => {
  if (!userProfiles || !Array.isArray(userProfiles)) userProfiles = [];

  let selectedProfId = Cookies.get(`selectedprofileid-${envName}`) || 0;
  let selectedProfName = Cookies.get(`selectedprofile-${envName}`) || '';

  if (userProfiles.length > 0 && userProfiles.filter(prof => prof.ProfileId == selectedProfId).length === 0) {
    selectedProfId = userProfiles[0].ProfileId || 0;
    selectedProfName = userProfiles[0].Name || 0;

    Cookies.set(`selectedprofileid-${envName}`, selectedProfId, {
      expires: 7,
      domain: DOMAIN_NAME,
      secure: !IS_LOCALHOST
    });
    Cookies.set(`selectedprofile-${envName}`, selectedProfName, {
      expires: 7,
      domain: DOMAIN_NAME,
      secure: !IS_LOCALHOST
    });
  }
};

const setSelectedOrganisation = (organisations, CurrentOrganisationId) => {
  if (!organisations || !Array.isArray(organisations)) organisations = [];
  if (CurrentOrganisationId) {
    Cookies.set(`selectedorganisationid-${envName}`, CurrentOrganisationId, {
      expires: 7,
      domain: DOMAIN_NAME,
      secure: !IS_LOCALHOST
    });
  }

  let selectedOrgId = Cookies.get(`selectedorganisationid-${envName}`) || 0;

  if (organisations.length > 0 && organisations.filter(org => org.OrganisationId == selectedOrgId).length === 0) {
    selectedOrgId = organisations[0].OrganisationId | 0;
    Cookies.set(`selectedorganisationid-${envName}`, selectedOrgId, {
      expires: 7,
      domain: DOMAIN_NAME,
      secure: !IS_LOCALHOST
    });

    let selectOrgDetails = organisations.filter(org => org.OrganisationId == selectedOrgId);
    if (selectOrgDetails.length > 0) {
      Cookies.set(`selectedorganisationlogo-${envName}`, selectOrgDetails[0].OrganisationLogo, {
        expires: 7,
        domain: DOMAIN_NAME,
        secure: !IS_LOCALHOST
      });
    }
  }
};

export const refreshTokenThunk = () => dispatch => {
  const accessToken = Cookies.get(`access-${envName}`);
  const decodedToken = decode(accessToken);

  if (decodedToken) {
    const expiryTime = moment.unix(decodedToken.exp);

    const refreshTimeWindow = moment.unix(decodedToken.exp).subtract(15, 'minutes');

    if (moment().isBetween(refreshTimeWindow, expiryTime)) {
      const token = Cookies.get(`refresh-${envName}`);
      dispatch(refreshToken({ token }));
    }
  }
};

export const refreshToken = data =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_SECURITY}/token`,
    method: 'POST',
    onSuccess: refreshTokenSuccess,
    onFailure: logout,
    data,
    skipRefresh: true
  });

export const refreshTokenSuccess = (response, { token }) => () => {
  const authData = get(response, ['AuthenticationResult']);

  if (authData) {
    setUserAuthData({ ...authData, RefreshToken: token });
  }
};

export const submitLogin = data => dispatch => {
  let log = logEntry(USER_MANAGEMENT, USER_LOGIN, { username: data.username });
  data.log = log;
  dispatch(submitUserLogin(data));
};

export const submitUserLogin = data =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_SECURITY}/login`,
    method: 'POST',
    onSuccess: loginSuccess,
    onFailure: msg => setLoginError(true, msg),
    onLoad: setIsLoading,
    data,
    skipRefresh: true
  });

export const loginSuccess = (response, { username }) => dispatch => {
  const userData = { username };
  let redirectURL = '/dashboard';
  let authData = get(response, ['AuthenticationResult']);
  if (!authData) {
    authData = {};
  }
  authData.Name = get(response, ['Name']);
  authData.UserName = get(response, ['UserName']);
  authData.UserProfiles = get(response, ['UserProfiles']);
  authData.UserId = get(response, ['UserId']);
  authData.Organisations = get(response, ['Organisations']) || [];
  authData.Session = get(response, ['Session']) || '';
  authData.CurrentOrganisationId = get(response, ['CurrentOrganisationId']);

  if (authData) {
    setUserAuthData(authData);
  }

  if (get(response, ['ChallengeName']) === 'NEW_PASSWORD_REQUIRED') {
    Object.assign(userData, {
      sessionId: get(response, ['Session']),
      newPasswordRequired: true
    });
    redirectURL = '/update-password';
  } else if (get(response, ['ChallengeName']) === 'SMS_MFA') {
    Object.assign(userData, {
      sessionId: get(response, ['Session']),
      newPasswordRequired: false
    });
    redirectURL = '/multi-factor-auth';
  }

  dispatch(setUserDetails(userData));

  if (redirectURL === '/dashboard') {
    dispatch(restoreChartConfig());
  }
  dispatch(push(redirectURL));
};

export const submitUpdatePassword = data => dispatch => {
  let log = logEntry(USER_MANAGEMENT, UPDATE_USER_PASSWORD_FIRST_LOGIN);
  data.log = log;
  dispatch(submitUpdateUserPassword(data));
};

export const submitUpdateUserPassword = data =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_SECURITY}/challenge`,
    method: 'POST',
    onSuccess: updatePasswordSuccess,
    onFailure: () => setLoginError(true),
    data,
    skipRefresh: true
  });

export const updatePasswordSuccess = response => dispatch => {
  const authData = get(response, ['AuthenticationResult']);
  const challengeName = get(response, ['ChallengeName']);
  const Session = get(response, ['Session']);

  if (authData) {
    const userData = { newPasswordRequired: false, sessionId: '' };
    setUserAuthData(authData);
    dispatch(setUserDetails(userData));
    dispatch(push('/dashboard'));
  } else if (challengeName === 'SMS_MFA' && Session !== '') {
    Cookies.set(`session-${envName}`, Session, { expires: 1, domain: DOMAIN_NAME, secure: !IS_LOCALHOST });
    dispatch(push('/multi-factor-auth'));
  }
};
export const postRemoteLogout = log =>
  apiAction({
    accessToken: Cookies.get(`access-${envName}`),
    url: `${process.env.REACT_APP_API_ENDPOINT_SECURITY}/logout`,
    method: 'POST',
    skipCallback: true,
    skipRefresh: true,
    data: {
      log
    }
  });

export const logout = (remoteLogout = true) => dispatch => {
  if (remoteLogout) {
    let log = logEntry(USER_MANAGEMENT, USER_LOGOUT);
    dispatch(postRemoteLogout(log));
  }
  Object.keys(Cookies.get()).forEach(cookieName => {
    if (
      cookieName !== `defaultLanguage-${envName}` &&
      cookieName !== `selectedprofileid-${envName}` &&
      cookieName !== `selectedorganisationid-${envName}` &&
      cookieName !== `selectedprofile-${envName}` &&
      cookieName !== `defaultTimezone-${envName}` &&
      cookieName !== `username-${envName}` &&
      cookieName !== `userid-${envName}` &&
      cookieName !== `selectedstatisticsperiod-${envName}` &&
      cookieName.trim().endsWith(`-${envName}`)
    ) {
      Cookies.remove(cookieName, { domain: DOMAIN_NAME, secure: !IS_LOCALHOST });
    }
  });
  dispatch(logoutUser());
  dispatch(push('/'));
};

//MFA START

export const submitSMSVerificationThunk = data => dispatch => {
  let log = logEntry(USER_MANAGEMENT, USER_MFA, { username: data.email, session: data.session, verificationCode: data.verificationCode });
  data.log = log;
  dispatch(submitSMSVerification(data));
};

export const submitSMSVerification = data =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_SECURITY}/login`,
    method: 'POST',
    onSuccess: loginSuccess,
    onFailure: err => setSMSVerificationErrorThunk(err),
    onLoad: setIsLoading,
    data,
    skipRefresh: true
  });

export const setSMSVerificationErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setSMSVerificationError(messageCode));
};

export const setSMSVerificationError = messageCode => {
  return {
    type: MFA_VERIFICATION_ERROR,
    payload: {
      value: messageCode
    }
  };
};

//MFA END
