import React, { useEffect, useState, Fragment } from 'react';
import s from './StaticData.module.scss';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import ContentTemplate from '../ContentTemplate/ContentTemplate';
import Banner from '../Banner/Banner';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getStaticData, getUserProfileWidget, getCurrentTimezone, getUnitManager } from '../../selectors/index';
import { setStaticDataChangeThunk, describeStaticDataThunk } from '../../actions/staticData';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Checkbox from '../WSAControls/CheckboxContainer/Checkbox';
import GoBack from '../WSAControls/GoBack/GoBack';
import { isRequired } from '../../utils';
import uuidv4 from 'uuid/v4';
import Table from 'react-bootstrap/Table';
import moment from 'moment-timezone';

const StaticData = ({ intl }) => {
  const dispatch = useDispatch();
  const staticDataManager = useSelector(state => getStaticData(state));
  const unitManager = useSelector(state => getUnitManager(state));
  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const currentTimezone = useSelector(state => getCurrentTimezone(state));
  const [localformErrors, setFormErrors] = useState({});
  let IsEdit = false;
  let IsView = false;
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;
  let { unitSerialNumber, unitId, staticDataId } = useParams();
  staticDataId = parseInt(staticDataId);

  useEffect(() => {
    if (
      (staticDataId && !staticDataManager) ||
      !staticDataManager.selectedStaticData ||
      !staticDataManager.selectedStaticData.UnitStaticDataId
    )
      describeStaticDataThunk(staticDataId, 'UNITSTATICDATAUPDATE');
  }, [describeStaticDataThunk, staticDataId]);

  const setLocalStaticData = currentState => {
    dispatch(setStaticDataChangeThunk(currentState));
  };

  //on control value change
  const onChange = e => {
    if (e.target && e.target.type && e.target.type === 'checkbox') {
      setLocalStaticData({
        ...staticDataManager,
        staticDataManager: { ...staticDataManager.staticDataManager, [e.target.name]: e.target.checked }
      });
    } else {
      setLocalStaticData({
        ...staticDataManager,
        selectedStaticData: { ...staticDataManager.selectedStaticData, [e.target.name]: e.target.value }
      });
    }
  };

  const telemetryEditHandler = id => {};

  const telemetryDeleteHandler = id => {};

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (
      !staticDataManager ||
      !staticDataManager.selectedStaticData ||
      !staticDataManager.selectedStaticData.Name ||
      isRequired(staticDataManager.selectedStaticData.Name, 1)
    ) {
      formErrors.Name = intl.formatMessage({
        id: 'staticData.nameMandatory',
        defaultMessage: 'Name is a mandatory field'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  let messageId = (staticDataManager && staticDataManager.displayMessageCode) || '';
  let messageText = '';
  messageText = messageId !== '' ? intl.formatMessage({ id: messageId, defaultMessage: messageId }) : '';

  return (
    <div className={s.staticData}>
      {staticDataManager.isLoading && <LoadingSpinner />}
      <Banner
        failureText={messageText}
        showBanner={staticDataManager.showBanner}
        status={staticDataManager.isOpSuccessful}
        successText={messageText}
      />
      <ContentTemplate
        selectedPage="staticData"
        userProfileWidgets={userProfileWidgets}
        widgetCode={'UNITSTATICDATALIST'}
        tagoutRequired={true}
      >
        <div className={s.contentWrapper}>
          <div className={s.schedulerHeader}>
            <GoBack className={s.backLink}>
              &lt; &nbsp;
              <FormattedMessage id="schedule.back" defaultMessage="BACK" />
            </GoBack>
            <h3 data-unittest="headingLabel">
              <FormattedMessage id="staticData.staticData'" defaultMessage="New Static Data" />
            </h3>
          </div>
          <div className={s.staticDataContent}>
            <Row className={s.breakrow}>
              <Col sm={3} lg={6}>
                <Form.Group controlId="frmstaticDataName">
                  <Form.Label>
                    <FormattedMessage id="staticData.name" defaultMessage="Name" />
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="Name"
                    onChange={onChange}
                    value={staticDataManager.selectedStaticData.Name}
                    className={`${s.formControl} ${localformErrors && localformErrors.Name ? s.formControlError : ''}`}
                  />
                  {localformErrors && localformErrors.Name && (
                    <p role="alert" className={s.error}>
                      {localformErrors.Name}
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={3} lg={6}>
                <Form.Group controlId="frmStaticDataDescription">
                  <Form.Label>
                    <FormattedMessage id="staticData.description" defaultMessage="Description" />
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    name="Description"
                    onChange={onChange}
                    placeholder={intl.formatMessage({
                      id: 'staticData.enterDescription',
                      defaultMessage: 'Enter Static data description'
                    })}
                    value={staticDataManager.selectedStaticData.Description}
                    className={`${s.textArea} ${localformErrors && localformErrors.Description ? s.formControlError : ''}`}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="formActiveStaticData">
                  <Checkbox
                    key={uuidv4()}
                    dataArray={[
                      {
                        SKEY: 'IsActive',
                        target: { type: 'checkbox' },
                        label: intl.formatMessage({
                          id: 'staticData.active',
                          defaultMessage: 'Static Data Active'
                        }),
                        isChecked: staticDataManager.selectedStaticData.IsActive
                      }
                    ]}
                    onSelectionChange={onChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <StaticDataTelemetryTable
                  currentTimezone={currentTimezone}
                  data={staticDataManager.selectedStaticData.TelemetryData}
                  editHandler={telemetryEditHandler}
                  deleteHandler={telemetryDeleteHandler}
                />
              </Col>
            </Row>
          </div>
        </div>
      </ContentTemplate>
    </div>
  );
};

const StaticDataTelemetryTable = ({ data, currentTimezone, editHandler, deleteHandler }) => {
  var d = new Date();

  return (
    <div className="">
      <Table variant className={s.innerTable}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="staticData.componentPosition" defaultMessage="Component Position" />
            </th>
            <th>
              <FormattedMessage id="staticData.telemetryValue" defaultMessage="Telemetry Value" />
            </th>
            <th>
              <FormattedMessage id="staticData.telemetryStartDate" defaultMessage="Start Date" />
            </th>
            <th>
              <FormattedMessage id="staticData.telemetryEndDate" defaultMessage="End Date" />
            </th>
            <th>
              <FormattedMessage id="staticData.isPublishable" defaultMessage="Is Publishable" />
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.map(n => {
            return (
              <Fragment key={n.UnitStaticDataTelemetryId}>
                <tr key={n.UnitStaticDataTelemetryId} data-unittest="staticData">
                  <td>{n.ModelComponentPositionName || ''}</td>
                  <td>{n.Value}</td>
                  <td>
                    {n.StartDateTime
                      ? moment
                          .unix(n.StartDateTime)
                          .tz(currentTimezone)
                          .format('DD-MMM-YYYY HH:mm z')
                      : '-'}
                  </td>

                  <td>
                    {n.EndDateTime
                      ? moment
                          .unix(n.EndDateTime)
                          .tz(currentTimezone)
                          .format('DD-MMM-YYYY HH:mm z')
                      : '-'}
                  </td>
                  <td>{n.IsPublishable}</td>
                  <td>
                    <Button variant="outline-secondary" className={s.changeButton} onClick={() => editHandler(n.UnitStaticDataId)}>
                      <FormattedMessage id="staticData.edit" defaultMessage="Edit" />
                    </Button>
                    <Button variant="outline-secondary" className={s.changeButton} onClick={() => deleteHandler(n.UnitStaticDataId)}>
                      <FormattedMessage id="staticData.remove" defaultMessage="Remove" />
                    </Button>
                  </td>
                </tr>
              </Fragment>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default injectIntl(StaticData);
