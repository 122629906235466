import { decode } from 'jsonwebtoken';
import moment from 'moment';
import { get as getCookie } from 'js-cookie';
import get from 'lodash.get';
import {
  GLOBAL_SETTINGS_TYPE,
  FLOWSEQUENCE_TYPE,
  SERVICE_TYPE,
  ALARM_STATUS_NEW,
  ALARM_STATUS_PENDING,
  ALARM_STATUS_RESOLVED
} from '../constants/index';
import { DEV_ENV, TEST_ENV, STAGING_ENV, IS_LOCALHOST } from '../constants/index';
import Cookies from 'js-cookie';

export const getUserState = state => state.user;
export const getUiState = state => state.ui;
export const getDevicesState = state => state.devices;
export const getChartState = state => state.charts;
export const getEventState = state => state.events;
export const getRouterState = state => state.router;
export const getSensorState = state => state.sensors;
export const getThresholdsState = state => state.thresholds;
export const getLocaleState = state => state.locale;
export const getTimezoneState = state => state.timezone;
export const getProfileState = state => state.profile;
export const getSidebarState = state => state.sidebarOpen;

export const getUserManagerState = state => state.userManager;
export const getUserProfileWidgetState = state => state.userProfileWidgets;
export const getUserProfileUnitState = state => state.userProfileUnits;
export const getAppWidgetsState = state => state.appWidgets;
export const getEventActionState = state => state.eventAction;
export const getCalibrationEquationState = state => state.calibrationEquation;
export const getChecklistTemplateState = state => state.checklistTemplate;
export const getChecklistState = state => state.checklist;
export const getCalibrationState = state => state.calibration;
export const getPageRedirectionState = state => state.redirectionData;

//Task Template
export const getTaskTemplateState = state => state.taskTemplate;
export const getTaskTemplateManager = state => getTaskTemplateState(state).taskTemplate;

//Tasks
export const getTasksState = state => state.tasks;
export const getTaskManager = state => getTasksState(state).tasks;

//units
export const getUnitManagerState = state => state.unitManager;

//organisations
export const getOrganisationManagerState = state => state.organisationManager;
export const getOrganisationManager = state => getOrganisationManagerState(state).organisationManager;

//model components
export const getModelComponentPositionManagerState = state => state.modelComponentPositionManager;
export const getModelComponentPositionManager = state => getModelComponentPositionManagerState(state).modelComponentPositionManager;
export const getModelComponentPositionManagerWithParentList = state => {
  let modelPositionManager = getModelComponentPositionManagerState(state).modelComponentPositionManager;
  modelPositionManager = {
    ...modelPositionManager,
    modelComponentPositions: modelPositionManager.modelComponentPositions.filter(item => item.ParentModelComponentPositionId == null)
  };

  return modelPositionManager;
};
export const getModelComponentPositions = state => getModelComponentPositionManager(state).modelComponentPositions;

//alarms
export const getAlarmsState = state => state.alarms;
export const getAlarms = state => getAlarmsState(state).alarms;
export const getAlarmList = state => getAlarms(state).alarmsList;
export const getAlarmFilters = state => getAlarms(state).filterData;

//models
export const getModelManagerState = state => state.modelManager;
export const getModelManager = state => getModelManagerState(state).modelManager;

// Component Positions Models
export const getComponentPositionsModel = state => getModelManager(state).selectedModel.ComponentPositionModel;

//model versions
export const getModelVersionManagerState = state => state.modelVersionManager;
export const getModelVersionManager = state => getModelVersionManagerState(state).modelVersionManager;

//model versions Thresholds
export const getModelVersionThreshold = state => getModelVersionManagerState(state).modelVersionManager.selectedModelVersion.Thresholds;

//model versions Units
export const getModelVersionUnits = state => getModelVersionManagerState(state).modelVersionManager.selectedModelVersion.ModelVersionUnit;

//model versions Units
export const getModelVersionModelComponentPositions = state =>
  getModelVersionManagerState(state).modelVersionManager.selectedModelVersion.ModelComponentPositions;

//model versions Globa; Settings
export const getModelVersionGlobalSettings = state =>
  getModelVersionManagerState(state).modelVersionManager.selectedModelVersion.GlobalSettings;

//Global Settings State
export const getGlobalSettingsState = state => state.globalSettings;
export const getGlobalSettings = state => getGlobalSettingsState(state).globalSettings;
// USERS
export const getUserLoginError = state => getUserState(state).loginError;
export const getUserLoginErrorMessage = state => getUserState(state).loginErrorMessage;
export const getUserLoginIsForgotPassword = state => getUserState(state).isforgotPassword;

export const getUsername = state => getUserState(state).username;
export const getUserSessionId = state => getUserState(state).sessionId;
export const getIsUserAuthenticated = () => {
  // const envName = process.env.REACT_APP_ENV_NAME;
  // if (envName === DEV_ENV || envName === TEST_ENV || envName === STAGING_ENV) {
  //   Cookies.remove('refresh', { domain: ' watersourceaustralia.com.au', secure: !IS_LOCALHOST });
  //   Cookies.remove('username', { domain: ' watersourceaustralia.com.au', secure: !IS_LOCALHOST });
  //   Cookies.remove('userid', { domain: ' watersourceaustralia.com.au', secure: !IS_LOCALHOST });
  //   Cookies.remove('name', { domain: ' watersourceaustralia.com.au', secure: !IS_LOCALHOST });
  //   Cookies.remove('session', { domain: ' watersourceaustralia.com.au', secure: !IS_LOCALHOST });
  //   Cookies.remove('profiles', { domain: ' watersourceaustralia.com.au', secure: !IS_LOCALHOST });
  //   Cookies.remove('organisations', { domain: ' watersourceaustralia.com.au', secure: !IS_LOCALHOST });
  //   Cookies.remove('selectedprofile', { domain: ' watersourceaustralia.com.au', secure: !IS_LOCALHOST });
  //   Cookies.remove('selectedprofileid', { domain: ' watersourceaustralia.com.au', secure: !IS_LOCALHOST });
  //   Cookies.remove('selectedorganisationid', { domain: ' watersourceaustralia.com.au', secure: !IS_LOCALHOST });
  //   Cookies.remove('access', { domain: ' watersourceaustralia.com.au', secure: !IS_LOCALHOST });
  // }
  const accessToken = getCookie(`access-${process.env.REACT_APP_ENV_NAME_SHORT}`);
  const decodedToken = decode(accessToken);

  if (!decodedToken) return false;

  return moment.unix(decodedToken.exp) > moment();
};

export const getUser = state => getUserState(state).user;
export const getUserManager = state => getUserManagerState(state).userManager;
export const getUserProfileWidget = state => getUserProfileWidgetState(state);
export const getUserProfileUnit = state => getUserProfileUnitState(state);
export const getEventAction = state => getEventActionState(state).eventAction;
export const getUserProfileWidgetsPresent = state => getUserProfileWidget(state).isProfileWidgetsPresent;

export const getUserByUserName = (state, email) => {
  if (!email) {
    return {};
  }
  let userManager = getUserManager(state);
  let users = (userManager && userManager.users) || null;
  if (!users) {
    return {};
  }

  let retUsers =
    (users &&
      Array.isArray(users) &&
      users.length > 0 &&
      users.filter(e => e.Email === email).length > 0 &&
      users.filter(e => e.Email === email)) ||
    null;
  if (!retUsers) {
    return {};
  }
  return retUsers[0];
};

// UI
export const getIsLoading = state => getUiState(state).isLoading;

// Devices
export const getSelectedUnitSerialNumber = state => getDevicesState(state).unitSerialNumber;

export const getDeviceIsLoading = state => getDevicesState(state).isLoading || false;
export const getDevices = state => getDevicesState(state).devices;
export const getSelectedView = state => getDevicesState(state).selectedView;
export const getCommandButtonStatus = state => getDevicesState(state).disableCommandButtons;
export const getBannerStatus = state => getDevicesState(state).showBanner;
export const getCommandStatus = state => getDevicesState(state).commandStatus;
export const getCommandId = state => getDevicesState(state).commandId;
export const getDevicesStatus = state => getDevicesState(state).devicesStatus;
export const getDevicesAlarms = state => getDevicesState(state).alarmsList;
export const getDevicesUnitComponentParts = state => getDevicesState(state).unitComponentParts;
export const getDevicesUnitSubComponent = state => getDevicesState(state).unitSubComponentDetails;
export const getSelectedUnitTagStatus = state => {
  return (getUnitManager(state) && getUnitManager(state).selectedUnit && getUnitManager(state).selectedUnit.IsTaggedOut) || 0;
};

export const getDeviceDisplayMessage = state => {
  const displayMessageCode = getDevicesState(state).displayMessageCode;
  return displayMessageCode || '';
};

export const getDeviceErrorStatus = state => {
  const isOpSuccessful = getDevicesState(state).isOpSuccessful;
  return isOpSuccessful || false;
};

export const getSelectedDevice = state => {
  const unitSerialNumber = getSelectedUnitSerialNumber(state);
  return getDevices(state)[unitSerialNumber] || {};
};
export const getSelectedDeviceDefault = state => {
  const unitSerialNumber = getSelectedUnitSerialNumber(state);
  return getDevices(state)[unitSerialNumber] || { unitSerialNumber: '' };
};

export const getDeviceSequence = state => {
  const unitSerialNumber = getSelectedUnitSerialNumber(state);
  const deviceSequences = getSequences(state);
  return get(deviceSequences, [unitSerialNumber, 'sequence'], 1050);
};

export const getSequences = state => getDevicesState(state).sequences;

export const getDeviceTimezone = state => getDevicesState(state).deviceTimezone;

export const getDeviceThresholds = state => {
  const unitSerialNumber = getSelectedUnitSerialNumber(state);
  const thresholds = getThresholdsState(state);
  return get(thresholds, [unitSerialNumber], {});
};

export const getDeviceShadow = state => {
  const thresholds = getDeviceThresholds(state);
  const sequenceThresholds = get(thresholds, ['shadow', 'sequencesensorthresholds'], {});
  const global = get(thresholds, ['shadow', 'global']);
  const sensorcalibration = get(thresholds, ['shadow', 'sensorcalibration']);
  return { ...sequenceThresholds, global, sensorcalibration };
};

export const getShowThresholdBanner = state => getDeviceThresholds(state).showBanner || false;
export const getThresholdLoadingStatus = state => getDeviceThresholds(state).isLoading || false;
export const getThresholdUpdateStatus = state => getDeviceThresholds(state).updateStatus;
export const getIsThresholdSnapshotNull = state => getDeviceThresholds(state).snapshot === null;

// Charts
export const getDeviceCharts = state => {
  const unitSerialNumber = getSelectedUnitSerialNumber(state);
  return getChartState(state)[unitSerialNumber] || {};
};

//Events
export const getDeviceEvents = state => {
  const unitSerialNumber = getSelectedUnitSerialNumber(state);
  return getEventState(state)[unitSerialNumber] || {};
};

export const getRoutePathname = state => getRouterState(state).location.pathname;

export const getEventDataByView = state => {
  const currentRoute = getRoutePathname(state);
  const view = currentRoute.includes('/events') ? 'viewAll' : 'processFlow';
  return getDeviceEvents(state)[view] || {};
};

export const getEventData = state => getEventDataByView(state).items || [];
export const getEventInputs = state => getEventDataByView(state).inputs;
export const getIsEventLoading = state => getEventDataByView(state).isLoading;

//Sensors
export const getDeviceSensors = state => {
  const unitSerialNumber = getSelectedUnitSerialNumber(state);
  return getSensorState(state)[unitSerialNumber] || {};
};

export const getDeviceSensorsLoadingStatus = state => {
  return getSensorState(state).isLoading || false;
};

export const getDeviceOnlineStatus = state => {
  const { timestamp } = getDeviceSensors(state);
  return moment(timestamp).isBetween(moment().subtract(1, 'minute'), moment());
};

export function getCurrentLocale(state) {
  return getLocaleState(state).locale;
}

export const getCurrentTimezone = state => getTimezoneState(state).timezone;

//Profile
export const getProfile = state => getProfileState(state).profile;

export const getProfileByIdInState = (state, Id) => {
  if (!Id) {
    return {};
  }
  let profiles = (getProfileState(state) && getProfileState(state).profile && getProfileState(state).profile.profiles) || null;
  if (!profiles) {
    return {};
  }

  let retProfiles =
    (profiles &&
      Array.isArray(profiles) &&
      profiles.length > 0 &&
      profiles.filter(e => e.Id === Id).length > 0 &&
      profiles.filter(e => e.Id === Id)) ||
    null;
  if (!retProfiles) {
    return {};
  }
  return retProfiles[0];
};

//Sidebar state
export const getCurrentSidebarState = state => getSidebarState(state).sidebarOpen;

export const getSelectedProfile = state => {
  return (getProfileState(state) && getProfileState(state).profile && getProfileState(state).profile.selectedProfile) || {};
};

//App widgets
export const getAppWidgets = state => getAppWidgetsState(state).appWidgets;

//units
export const getUnitManager = state => {
  return getUnitManagerState(state).unitManager;
};

export const getSelectedUnit = state => {
  return (getUnitManager(state) && getUnitManager(state).selectedUnit) || {};
};

//Selected Unit tag details
export const getSelectedUnitTagOut = state => {
  if (getUnitManagerState(state) && getUnitManagerState(state).unitManager.selectedUnit) {
    return getUnitManagerState(state).unitManager.selectedUnit.tagoutDetails;
  }
  return {
    IsTaggedOut: 0,
    UnitTagOutId: 0
  };
};

export const getUnitSettings = state => {
  return (getUnitManager(state) && getUnitManager(state).unitSetting) || {};
};

//Selected Unit Components
export const getSelectedUnitComponentPositions = state => {
  if (getUnitManagerState(state) && getUnitManagerState(state).unitManager.selectedUnit) {
    return getUnitManagerState(state).unitManager.selectedUnit.unitComponentPositions;
  }
  return [];
};

//global settings
export const getUnitGlobalSettings = state => {
  let generalGlobalSettings = getGlobalSettings(state);
  if (generalGlobalSettings && generalGlobalSettings.globalSettings && Array.isArray(generalGlobalSettings.globalSettings)) {
    return generalGlobalSettings.globalSettings.filter(x => x.Type === GLOBAL_SETTINGS_TYPE);
  } else {
    return [];
  }
};

export const getFlowSequenceGlobalSettings = state => {
  let generalGlobalSettings = getGlobalSettings(state);
  if (generalGlobalSettings && generalGlobalSettings.globalSettings && Array.isArray(generalGlobalSettings.globalSettings)) {
    return generalGlobalSettings.globalSettings.filter(x => x.Type === FLOWSEQUENCE_TYPE);
  } else {
    return [];
  }
};

export const getServiceTypes = state => {
  let generalGlobalSettings = getGlobalSettings(state);
  if (generalGlobalSettings && generalGlobalSettings.globalSettings && Array.isArray(generalGlobalSettings.globalSettings)) {
    return generalGlobalSettings.globalSettings.filter(x => x.Type === SERVICE_TYPE);
  } else {
    return [];
  }
};

export const getAllModelComponentPositions = state => {
  let generalGlobalSettings = getGlobalSettings(state);
  if (
    generalGlobalSettings &&
    generalGlobalSettings.modelComponentPositions &&
    Array.isArray(generalGlobalSettings.modelComponentPositions)
  ) {
    return generalGlobalSettings.modelComponentPositions;
  } else {
    return [];
  }
};

export const getSensorModelComponentPositions = state => {
  let generalGlobalSettings = getGlobalSettings(state);
  if (
    generalGlobalSettings &&
    generalGlobalSettings.modelComponentPositions &&
    Array.isArray(generalGlobalSettings.modelComponentPositions)
  ) {
    return generalGlobalSettings.modelComponentPositions.filter(x => x.IsParent === 0 && x.NeedFeedback);
  } else {
    return [];
  }
};

export const getWarningEvents = state => {
  let generalGlobalSettings = getGlobalSettings(state);
  if (generalGlobalSettings && generalGlobalSettings.eventCodeDetails && Array.isArray(generalGlobalSettings.eventCodeDetails)) {
    return generalGlobalSettings.eventCodeDetails.filter(x => x.Severity === 1);
  } else {
    return [];
  }
};

export const getAlarmEvents = state => {
  let generalGlobalSettings = getGlobalSettings(state);
  if (generalGlobalSettings && generalGlobalSettings.eventCodeDetails && Array.isArray(generalGlobalSettings.eventCodeDetails)) {
    return generalGlobalSettings.eventCodeDetails.filter(x => x.Severity === 2);
  } else {
    return [];
  }
};

export const getInfoEvents = state => {
  let generalGlobalSettings = getGlobalSettings(state);
  if (generalGlobalSettings && generalGlobalSettings.eventCodeDetails && Array.isArray(generalGlobalSettings.eventCodeDetails)) {
    return generalGlobalSettings.eventCodeDetails.filter(x => x.Severity === 4);
  } else {
    return [];
  }
};

export const getGlobalSettingsDataPresent = state => {
  return getGlobalSettings(state).isDataPresent;
};

//Event Action & Event Action Activity
export const getEventActionStatusFromState = state => {
  return state.eventActionStatus;
};

export const getEventActionStatus = state => getEventActionStatusFromState(state).eventActionStatus;

export const getUnitEventActions = state => getEventAction(state).UnitEventActions;

// get Alarm Status

export const getAlarmStatus = () => {
  let status = [];
  status.push({ text: ALARM_STATUS_NEW, value: ALARM_STATUS_NEW });
  status.push({ text: ALARM_STATUS_PENDING, value: ALARM_STATUS_PENDING });
  status.push({ text: ALARM_STATUS_RESOLVED, value: ALARM_STATUS_RESOLVED });
  return status;
};

// get Calibration Equations
export const getCalibrationEquation = state => getCalibrationEquationState(state).calibrationEquation;
export const getSelectedCalibrationEquation = state => {
  return (getCalibrationEquation(state) && getCalibrationEquation(state).SelectedCalibrationEquation) || {};
};

// get Checklist templates
export const getChecklistTemplate = state => getChecklistTemplateState(state).checklistTemplate;
export const getSelectedChecklistTemplate = state => {
  return (getChecklistTemplate(state) && getChecklistTemplate(state).SelectedChecklistTemplate) || {};
};

// get Checklist templates
export const getChecklist = state => getChecklistState(state).checklist;
export const getSelectedChecklist = state => {
  return (getChecklist(state) && getChecklist(state).SelectedChecklist) || {};
};

export const getCalibration = state => getCalibrationState(state).calibration;
export const getSelectedCalibration = state => {
  return (getCalibration(state) && getCalibration(state).SelectedCalibration) || {};
};

export const getPageRedirection = state => getPageRedirectionState(state).redirectionData;

//Event Code
export const getEventCodeState = state => state.eventCode;
export const getEventCode = state => getEventCodeState(state).eventCode;

//Component Parts
export const getComponentPartsState = state => state.componentParts;
export const getComponentParts = state => getComponentPartsState(state).componentParts;

//Model Component Type
export const getModelComponentTypeState = state => state.modelComponentType;
export const getModelComponentType = state => getModelComponentTypeState(state).modelComponentType;

//Approved Component
export const getApprovedComponentState = state => state.approvedComponent;
export const getApprovedComponent = state => getApprovedComponentState(state).approvedComponent;

//Unit Configuration
export const getUnitConfigurationState = state => state.unitConfiguration;
export const getUnitConfiguration = state => getUnitConfigurationState(state).unitConfiguration;

// Model Telemetry Links
export const getModelTelemetryLinkState = state => state.telemetryLinks;
export const getModelTelemetryLinks = state => getModelTelemetryLinkState(state).TelemetryLink;

//UnitTagHistory
export const getUnitTagHistoryState = state => state.unitTagoutHistory;
export const getUnitTagHistory = state => getUnitTagHistoryState(state).unitTagHistory;
export const getUnitTagHistoryList = state => getUnitTagHistory(state).alarmsList;
export const getUnitTagHistoryFilters = state => getUnitTagHistory(state).filterData;

export const getForgotPasswordState = state => state.forgotPassword;
export const getForgotPassword = state => getForgotPasswordState(state).forgotPassword;

export const getCommandSchedulerState = state => state.commandScheduler;
export const getCommandScheduler = state => getCommandSchedulerState(state).commandScheduler;

export const getUnitServiceState = state => state.unitService;
export const getUnitService = state => getUnitServiceState(state).unitService;

export const getDailySummaryState = state => state.dailySummary;
export const getDailySummary = state => getDailySummaryState(state).dailySummary;

export const getUnitStatisticsState = state => state.unitStatistics;
export const getUnitStatistics = state => getUnitStatisticsState(state).unitStatistics;

export const getTelemetryPartsState = state => state.telemetryParts;
export const getTelemetryParts = state => getTelemetryPartsState(state).telemetryParts;

export const getTelemetryTypes = () => {
  let tTypes = [];
  tTypes.push({ text: 'Cycle', value: 0 });
  tTypes.push({ text: 'Hours', value: 1 });
  tTypes.push({ text: 'Volume', value: 2 });
  return tTypes;
};

export const getNotificationsState = state => state.notifications;
export const getNotifications = state => getNotificationsState(state).notifications;

export const getFAQCategoryState = state => state.faqCategory;
export const getFAQCategory = state => getFAQCategoryState(state).faqCategory;

export const getFAQuestionState = state => state.faQuestion;
export const getFAQuestion = state => getFAQuestionState(state).faQuestion;

export const getOrganisationRegionState = state => state.organisationRegion;
export const getOrganisationRegion = state => getOrganisationRegionState(state).organisationRegion;

//model versions Global Settings
export const getModelVersionServicePlans = state =>
  getModelVersionManagerState(state).modelVersionManager.selectedModelVersion.ServicePlans;

export const getModelFirmwareVersionManagerState = state => state.modelFirmwareVersionManager;
export const getModelFirmwareVersionManager = state => getModelFirmwareVersionManagerState(state).modelFirmwareVersionManager;

export const getReleseNoteState = state => state.releaseNote;
export const getReleaseNote = state => getReleseNoteState(state).releaseNote;

//Unit global setting state
export const getModelVersionUnitGlobalSettings = state =>
  getModelVersionManagerState(state).modelVersionManager.selectedModelVersion.UnitGlobalSettings;

export const getUnitState = state => state.unit;
export const getUnit = state => getUnitState(state).unit;

//Sequence version
export const getSequenceVersionState = state => state.sequenceVersionManager;
export const getSequenceVersion = state => getSequenceVersionState(state).sequenceVersionManager;
export const getSequenceVersionFileManager = state => getSequenceVersion(state)?.selectedSequenceVersion;

//static data
export const getStaticDataState = state => state.staticDataManager;
export const getStaticData = state => getStaticDataState(state).staticDataManager;
