import defaultLocaleProvider from '../services/defaultLocaleProvider';
import defaultTimezoneProvider from '../services/defaultTimezoneProvider';
import { STATISTIC_PERIODS } from '../constants/index';
export const initialUserState = {
  loginError: false,
  loginErrorMessage: '',
  isforgotPassword: false
};

export const initialUserManagerState = {
  userManager: {
    users: [],
    selectedUser: {
      UserId: '',
      GuidIdentifier: '',
      Email: '',
      PhoneNumber: '',
      FirstName: '',
      LastName: '',
      FullName: '',
      DeliveryType: '',
      OrganisationId: 0,
      CreatedDateTime: 0,
      ModifiedDateTime: 0,
      CreatedByUserId: 0,
      ModifiedByUserId: 0,
      IsActive: true,
      IsDeleted: false,
      selectedProfiles: []
    },
    isLoading: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    filter: undefined,
    limit: 1000,
    currentPage: 1
  }
};

export const initialOrganisationManagerState = {
  organisationManager: {
    organisations: [],
    defaultProfiles: [],
    selectedOrganisation: {
      OrganisationId: '',
      GuidIdentifier: '',
      ParentOrganisationId: '',
      DefaultProfileId: '',
      OrganisationLogo: '',
      Name: '',
      Town: '',
      Region: '',
      Country: '',
      PostCode: '',
      CreatedDate: '',
      UpdatedDate: '',
      CreateddByUserId: '',
      ModifiedByUserId: '',
      UnitManagerId: 0,
      UnitTechnicianId: 0,
      UpdatedBy: '',
      IsActive: true,
      IsDeleted: false,
      Users: [],
      Units: [],
      SubOrganisations: [],
      TransferOrganisationId: 0,
      TransferUnits: [],
      SelectedTransferUnits: []
    },
    parentOrganisationData: {
      parentOrganisationUsers: [],
      parentOrganisationUnits: [],
      toFilterParentOrgUsers: [],
      toFilterParentOrgUnits: []
    },
    users: [],
    units: [],
    orgModelVersions: [],
    orgModels: [],
    toFilterUsers: [],
    toFilterUnits: [],
    isLoading: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    filter: undefined,
    limit: 1000,
    currentPage: 1
  }
};

export const initialModelState = {
  modelManager: {
    models: [],
    filteredResults: [],
    selectedModel: {
      ModelId: 0,
      GuidIdentifier: '',
      Name: '',
      ModelName: '',
      Description: '',
      SchematicDiagramName: 'DeviceSchematicSvg',
      UpdatedBy: '',
      IsActive: true,
      isLoading: false,
      showBanner: false,
      isOpSuccessful: false,
      displayMessageCode: '',
      filter: undefined,
      limit: 1000,
      DuplicateModel: {
        ComponentPositionLinks: false,
        TelemetryLinks: false,
        ModelVersions: []
      },

      ComponentPositionModel: {
        componentPositionModels: [],
        filteredResults: [],
        selectedcomponentPositionModel: {
          ModelComponentPositionId: 0,
          IsEdgeDevice: false,
          ModelId: 0
        },
        isLoading: false,
        isRedirect: false,
        showBanner: false,
        isOpSuccessful: false,
        displayMessageCode: '',
        filter: undefined,
        limit: 1000,
        currentPage: 1,
        showAddEditScreen: false
      }
    },
    isLoading: false,
    isRedirect: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    filter: undefined,
    limit: 1000,
    currentPage: 1
  }
};

export const initialComponentPositionModelState = {
  componentPositionModel: {
    componentPositionModels: [],
    filteredResults: [],
    selectedcomponentPositionModel: {
      ModelComponentPositionId: 0
    },
    isLoading: false,
    isRedirect: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    filter: undefined,
    limit: 1000,
    currentPage: 1
  }
};

export const initialModelVersionState = {
  modelVersionManager: {
    modelVersions: [],
    selectedModelVersion: {
      ModelVersionId: 0,
      GuidIdentifier: '',
      ModelId: 0,
      Name: '',
      OrganisationId: 0,
      SequenceVersionId: 0,
      OrganisationName: '',
      ModelName: '',
      Description: '',
      UpdatedBy: '',
      IsActive: true,
      SequenceVersions: [],
      GlobalSettings: {
        GlobalSettingsList: [],
        SelectedGlobalSetting: {
          ModelVersionGlobalSettingId: 0,
          GeneralGlobalSettingId: 0,
          ModelVersionId: 0,
          GuidIdentifier: '',
          Value: null,
          UnitOfMeasure: '',
          IsActive: true,
          CreatedDateTime: '',
          ModifiedDateTime: '',
          UpdatedBy: ''
        },
        showAddEditScreen: false,
        isLoading: false,
        showBanner: false,
        isOpSuccessfull: false,
        displayMessageCode: '',
        filter: undefined,
        limit: 1000
      },
      Thresholds: {
        ThresholdsList: [],
        SelectedThreshold: {
          ModelVersionThresholdId: 0,
          GeneralGlobalSettingId: 0,
          ModelComponentPositionId: 0,
          ModelVersionId: 0,
          GuidIdentifier: '',
          Min: null,
          Low: null,
          CriticalLow: null,
          CriticalHigh: null,
          High: null,
          Max: null,
          IsActive: true,
          CreatedDateTime: '',
          ModifiedDateTime: '',
          UpdatedBy: ''
        },
        showAddEditScreen: false,
        isLoading: false,
        showBanner: false,
        isOpSuccessful: false,
        displayMessageCode: '',
        filter: undefined,
        limit: 1000
      },
      ModelVersionUnit: {
        ModelVersionUnitsList: [],
        UnallocatedUnitsList: [],
        SelectedModelVersionUnits: [],
        showAddEditScreen: false,
        isLoading: false,
        showBanner: false,
        isOpSuccessful: false,
        displayMessageCode: '',
        filter: '',
        limit: 1000
      },
      ServicePlans: {
        ServicePlansList: [],
        SelectedServicePlan: {
          ModelVersionServicePlanId: 0,
          ChecklistTemplateId: 0,
          ModelVersionId: 0,
          GuidIdentifier: '',
          Name: '',
          Description: '',
          StartDateTime: 0,
          ScheduleBy: 'weekly',
          ScheduleByPeriod: 12,
          IsActive: true,
          CreatedDateTime: '',
          ModifiedDateTime: '',
          UpdatedBy: ''
        },
        showAddEditScreen: false,
        isLoading: false,
        showBanner: false,
        isOpSuccessfull: false,
        displayMessageCode: '',
        filter: undefined,
        limit: 1000
      },
      ModelComponentPositions: [],
      OrganisationHirarchies: [],
      UnitGlobalSettings: {
        UnitGlobalSettingsList: [],
        SelectedUnitGlobalSetting: {
          UnitGlobalSettingId: 0,
          ModelVerionId: 0,
          Code: '',
          Name: '',
          Description: '',
          UnitOfMeasure: '',
          DefaultValue: '',
          MinimumValue: '',
          MaximumValue: '',
          IsActive: true,
          TranslationCode: '',
          IsMinMaxDisabled: false
        },
        showAddEditScreen: false,
        isLoading: false,
        showBanner: false,
        isOpSuccessfull: false,
        displayMessageCode: '',
        filter: undefined,
        limit: 1000
      }
    },
    isLoading: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    filter: undefined,
    limit: 1000,
    currentPage: 1
  }
};

export const initialModelFirmwareVersionState = {
  modelFirmwareVersionManager: {
    modelFirmwareVersions: [],
    selectedModelFirmwareVersion: {
      ModelFirmwareVersionId: 0,
      GuidIdentifier: '',
      ModelId: 0,
      Name: '',
      Version: '',
      Description: '',
      ModelComponentPositionId: 0,
      ModelComponentPositionName: '',
      UpdatedBy: '',
      UploadFilePath: '',
      IsCurrentVersion: false,
      PublishedDateTime: 0,
      PublishedByUserId: 0,
      PublishedByUser: '',
      filter: '',
      limit: 1000,
      currentPage: 1,
      ModelUnits: [],
      Files: [],
      deletedFileIds: []
    },
    ModelComponentPositions: [],
    isLoading: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    filter: undefined,
    limit: 1000,
    currentPage: 1
  }
};

export const initialModelComponentPositionsState = {
  modelComponentPositionManager: {
    modelComponentPositions: [],
    parentModelComponentPositions: [],
    modelComponentTypes: [],
    selectedModelComponentPosition: {
      ModelComponentPositionId: 0,
      GuidIdentifier: '',
      Name: '',
      Title: '',
      Description: '',
      IsCCP: false,
      IsOCP: false,
      IsDerived: false,
      NeedUnitOfMeasure: '',
      NeedFeedback: false,
      NeedState: false,
      Quantity: 1,
      ModelComponentTypeId: null,
      RequireCalibration: false,
      IsActive: true,
      IsVirtual: true,
      PeriodicSummaryCycle: false,
      NeedPeriodicSummaryMin: false,
      NeedPeriodicSummaryMax: false,
      PeriodicSummaryVolume: false,
      PeriodicSummaryRunHours: false,
      StepAdjustable: false,
      StepValue: null,
      Operator: null,
      Expression: '',
      ParentModelComponentPositionId: null,
      ParentModelComponentPositionName: '',
      DisplayComponent: false,
      HasRaw: false,
      IsRaw: false,
      RawComponentName: '',
      ModelSubComponentTypeId: null,
      ModelSubComponentTypeName: '',
      ModelComponentSubPositions: [],
      ExistingComponentSubPositions: []
    },
    isLoading: false,
    isRedirect: false,
    isParentRedirect: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: ''
  }
};

export const initialAlarmsState = {
  alarms: {
    alarmsList: [],
    filterData: {
      EventCodes: [],
      Sensors: [],
      Units: [],
      Assignees: []
    },
    alarmListCount: 0,
    filteredResults: [],
    isLoading: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    filterBy: {
      filter: null,
      filterEventCode: null,
      filterSensor: null,
      filterStatus: null,
      filterUnit: null,
      filterAssignee: null
    },
    limit: 1000,
    currentPage: 1
  }
};

export const initialCalibrationEquationState = {
  calibrationEquation: {
    CalibrationEquationList: [],
    SelectedCalibrationEquation: {
      CalibrationEquationId: 0,
      Name: '',
      Description: '',
      GuidIdentifier: '',
      IsActive: true,
      EquationConstants: {
        EquationConstantList: [],
        SelectedEquationConstant: {
          CalibrationEquationConstantId: 0,
          CalibrationEquationId: 0,
          Name: '',
          Description: '',
          Formula: '',
          GuidIdentifier: '',
          isChanged: 0,
          IsActive: true,
          OrderNumber: 0
        },
        showAddEditScreen: false
      }
    },
    isLoading: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    filter: undefined,
    limit: 1000
  }
};

export const initialUnitManagerState = {
  unitManager: {
    units: [],
    selectedUnit: {
      UnitId: 0,
      ModelId: 0,
      OrganisationId: 0,
      GuidIdentifier: '',
      Name: '',
      SchematicDiagramName: 'DeviceSchematicSvg',
      UnitNumber: '',
      Description: '',
      CreatedDateTime: 0,
      ModifiedDateTime: 0,
      CreatedByUserId: 0,
      IsTaggedOut: 0,
      ModifiedByUserId: 0,
      UnitManagerId: 0,
      UnitManagerName: '',
      UnitTechnicianId: 0,
      UnitTechnicianName: '',
      CommissionGpsLatitude: null,
      CommissionGpsLongitude: null,
      WarrantyStartDate: null,
      WarrantyEndDate: null,
      IsActive: true,
      IsDeleted: false,
      unitComponentPositions: [],
      tagoutDetails: {
        IsTaggedOut: 0,
        UnitTagOutId: 0
      },
      InstallationStreetAddress: '',
      InstallationCity: '',
      InstallationRegion: '',
      InstallationPostCode: '',
      InstallationCountry: '',
      RegionId: 0,
      RegionName: '',
      RegionList: [],
      UserList: []
    },
    unitIotInfo: {
      privateKeyData: {
        filename: '',
        data: ''
      },
      certificateData: {
        filename: '',
        data: ''
      }
    },
    isLoading: false,
    isDataRendered: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    filter: undefined,
    limit: 1000,
    currentPage: 1,
    filteredResults: [],
    filterData: {
      unitRegions: [],
      models: []
    },
    unitListCount: 0,
    filterRegion: null,
    filterModel: null,
    unitSetting: {
      isLoading: false,
      isDataRendered: false,
      showBanner: false,
      isOpSuccessful: false,
      displayMessageCode: '',
      filter: undefined,
      unitSettings: []
    }
  }
};

export const initialUnitState = {
  unit: {
    selectedUnit: {
      UnitId: 0,
      ModelId: 0,
      ModelVersionId: 0,
      OrganisationId: 0,
      GuidIdentifier: '',
      Name: '',
      UnitNumber: '',
      Description: '',
      CreatedDateTime: 0,
      DeviceSerialNumber: '',
      ModifiedDateTime: 0,
      CreatedByUserId: 0,
      IsTaggedOut: 0,
      ModifiedByUserId: 0,
      UnitManagerId: 0,
      UnitManagerName: '',
      UnitTechnicianId: 0,
      UnitTechnicianName: '',
      ManufactureId: '',
      ManufactureDate: null,
      CommissionDate: null,
      CommissionStatus: false,
      CommissionPowerOnTest: false,
      CommissionWaterSample: '',
      CommissionCallHome: '',
      CommissionGpsLatitude: null,
      CommissionGpsLongitude: null,
      WarrantyStartDate: null,
      WarrantyEndDate: null,
      IsActive: true,
      IsDeleted: false,
      InstallationDate: null,
      InstallationStatus: false,
      InstallationEngineer: 0,
      InstallationStreetAddress: '',
      InstallationCity: '',
      InstallationRegion: '',
      InstallationPostCode: '',
      InstallationCountry: '',
      RegionId: 0,
      RegionName: '',
      BillOfMaterials: []
    },
    unitDropdownList: {
      unitComponentPositions: [],
      regionList: [],
      userList: [],
      approvedComponents: [],
      deviceList: [],
      models: [],
      componentParts: [],
      modelVersions: []
    },
    isLoading: false,
    isDataRendered: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: ''
  }
};

export const initialUnitReportsState = {
  search: {
    deviceId: '',
    unitSerialNumber: '',
    fromDate: 0,
    toDate: 0,
    selectAll: false,
    components: [],
    reportTypes: [
      { ModelComponentPositionId: 'TELEMETRY', Name: 'TELEMETRY', Description: 'Telemetry', ShowDataPoints: true, isChecked: true }
    ],
    showDataPoints: true,
    results: [],
    isLoading: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: ''
  }
};

export const initialUiState = {
  isLoading: false
};

export const initialDeviceState = {
  deviceError: false,
  disableCommandButtons: false,
  devices: {},
  deviceId: '',
  unitSerialNumber: '',
  deviceTimezone: '',
  showBanner: false,
  sequences: {},
  devicesStatus: {},
  alarmsList: [],
  unitComponentParts: [],
  unitSubComponentDetails: [],
  selectedView: null,
  isLoading: false,
  isOpSuccessful: true,
  displayMessageCode: ''
};

export const initialChartState = {};

export const initialEventsState = {
  eventsError: false
};
export const initialProfileState = {
  profile: {
    profiles: [],
    selectedProfile: {
      ProfileId: 0,
      Name: '',
      Description: '',
      GuidIdentifier: '',
      IsMFAEnabled: false,
      IsActive: true,
      IsDefault: false,
      ProfileWigets: [],
      formValid: false,
      errorCount: null,
      errors: {
        Name: { required: true, error: '', value: '' },
        Description: { required: true, error: '', value: '' }
      }
    },
    isLoading: false,
    showBanner: false,
    isOpSuccessful: true,
    errorMessage: '',
    isError: true,
    redirect: false
  },
  eventsError: false
};

export const initialLocaleState = {
  locale: defaultLocaleProvider(navigator)
};

export const initialTimezoneState = {
  timezone: defaultTimezoneProvider()
};

export const initialAppWidgetState = {
  appWidgets: {
    appWidgets: [],
    isLoading: false,
    isOpSuccessfull: false,
    showBanner: false
  }
};

export const initialSidebarState = {
  sidebarOpen: true
};

export const initialGlobalSettingsState = {
  globalSettings: {
    globalSettings: [],
    selectedGlobalSettings: {
      GeneralGlobalSettingId: 0,
      Code: '',
      Name: '',
      IsPredefined: false,
      Description: '',
      UnitOfMeasure: '',
      DefaultValue: '',
      MinimumValue: '',
      MaximumValue: '',
      Type: '',
      SequenceCode: null,
      ShowCommand: false,
      IsActive: true,
      TranslationCode: ''
    },
    modelComponentPositions: [],
    eventCodeDetails: [],
    isDataPresent: false,
    isLoading: false,
    isOpSuccessfull: false,
    showBanner: false,
    redirect: false
  }
};

export const initialEventActionStatus = {
  eventActionStatus: {
    unitNumber: '',
    Events: [],
    isLoading: false,
    isOpSuccessfull: false,
    showBanner: false
  }
};

export const initialEventAction = {
  eventAction: {
    EventAction: {
      EventActionId: 0,
      UnitNumber: '',
      UnitSerialNumber: '',
      UnitId: 0,
      EventCode: '',
      EventType: '',
      EventDateTime: 0,
      CreatedDateTime: 0,
      ResolvedDateTime: 0,
      EventActionAssignedToUserId: 0,
      CreatedByUserId: 1,
      EventCategory: '',
      EventTitle: '',
      EventDescription: '',
      LinkStatus: 0,
      ModelComponentPositionId: 0,
      EventStatus: 'NEW',
      GeneralGlobalSettingId: 0,
      IsActive: true,
      TrendAnalysisChart: {
        id: 'eventActionChart',
        data: [],
        dateFrom: 0,
        dateTo: 0,
        sensors: [],
        lowerThreshold: '',
        upperThreshold: '',
        limit: 200,
        resultType: 3,
        errorMessage: '',
        isLoading: false
      }
    },
    TelemetryDownloads: [],
    EventActionActivities: [],
    Tasks: [],
    NewActivity: {},
    UnitEventActions: [],
    LinkedAlarms: [],
    isLoading: false,
    isOpSuccessfull: false,
    showBanner: false
  }
};

export const initialEventActionActivity = {
  EventActionActivityId: 0,
  EventActivityId: 0,
  ActivityType: '',
  Description: '',
  EventStatus: '',
  AssignedUserId: 0,
  AssignedUserName: '',
  IsActive: 1,
  CreatedDateTime: 0,
  CreatedByUserId: 0,
  CreatedByUserName: ''
};

export const initialChecklistTemplateState = {
  checklistTemplate: {
    ChecklistTemplates: [],
    TaskTemplates: [],
    SelectedChecklistTemplate: {
      ChecklistTemplateId: 0,
      Name: '',
      IsServiceChecklist: false,
      ModelVersionServicePlanCount: 0,
      OrganisationId: 0,
      Description: '',
      GuidIdentifier: '',
      IsActive: true,
      CreatedDateTime: 0,
      ModifiedDateTime: 0,
      CreatedByUserId: 0,
      ModifiedByUserId: 0,
      SelectedTaskTemplates: []
    },
    isLoading: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    filter: undefined,
    limit: 1000,
    currentPage: 1
  }
};

export const initialChecklistState = {
  checklist: {
    Checklists: [],
    Tasks: [],
    SelectedChecklist: {
      ChecklistId: 0,
      Name: '',
      IsServiceChecklist: false,
      OrganisationId: 0,
      Description: '',
      ModelVersionServicePlanId: null,
      DueDateTime: 0,
      Status: '',
      UnitServiceId: 0,
      UnitId: null,
      AssignedToProfileId: null,
      ProfileName: null,
      AssignedToUserId: null,
      AssignedUsername: null,
      AssignedByUserId: null,
      AssignedByUsername: null,
      UnitName: null,
      UnitNumber: null,
      UnitSerialNumber: null,
      GuidIdentifier: '',
      IsActive: true,
      CreatedDateTime: 0,
      ModifiedDateTime: 0,
      CreatedByUserId: 0,
      ModifiedByUserId: 0,
      SelectedTasks: []
    },
    isLoading: false,
    showBanner: false,
    MarkAsComplete: false,
    isOpSuccessful: false,
    warning: false,
    displayMessageCode: '',
    checklistCount: 0,
    filteredResults: [],
    filterBy: {
      filter: null,
      filterStatus: null,
      filterIsServiceChecklist: '0',
      filterUnitId: null,
      filterAssignedByUser: null,
      filterCreatedByUser: null
    },
    limit: 1000,
    currentPage: 1
  }
};

export const initialTaskTemplatesState = {
  taskTemplate: {
    TaskTemplates: [],
    SelectedTaskTemplate: {
      TaskTemplateId: 0,
      OrganisationId: 0,
      Name: '',
      Description: '',
      GuidIdentifier: '',
      Activities: '',
      EstimatedEffort: 0,
      AssignedToProfileId: null,
      ProfileName: '',
      IsServiceTask: false,
      IsActive: '',
      CreatedDateTime: 0,
      ModifiedDateTime: 0,
      UpdatedBy: ''
    },
    isLoading: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    filter: undefined,
    limit: 1000,
    currentPage: 1
  }
};

export const initialCalibrationState = {
  calibration: {
    CalibrationList: [],
    SelectedCalibration: {
      CalibrationId: 0,
      UnitId: 0,
      UnitNumber: '',
      ComponentPartId: 0,
      CalibrationApprovedSubComponentId: 0,
      ComponentPartName: 0,
      ModelVersionId: 0,
      FullName: '',
      IsFinalized: false,
      IsUploaded: false,
      CalibrationDateTime: 0,
      CalibrationEquationId: 0,
      CalibrationEquationName: '',
      CalibrationEquationFormula: '',
      CalibrationRequired: false,
      Status: '',
      GuidIdentifier: '',
      IsActive: true,
      CreatedDateTime: 0,
      CreatedByUserId: 0,
      CalibrationAcceptedErrorTolerance: 0,
      CalibrationFrequency: '',
      OutputRangeLow: 0,
      OutputRangeHigh: 0,
      UnitOfMeasure: '',
      CalibrationResults: [],
      CalibrationComponentConstants: [],
      CalibrationComments: []
    },
    isViewMode: false,
    isLoading: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    filter: undefined,
    limit: 1000
  }
};

export const initialTasksState = {
  tasks: {
    taskList: [],
    selectedTask: {
      TaskId: 0,
      OrganisationId: 0,
      ChecklistId: null,
      Name: '',
      Description: '',
      GuidIdentifier: '',
      UnitId: null,
      Activities: '',
      EstimatedEffort: 0,
      BulkComplete: false,
      ActualEffort: 0,
      AssignedToProfileId: null,
      AssignedToUserId: null,
      AssignedByUserId: null,
      AssignedUsername: '',
      AssignedByUsername: '',
      IsServiceTask: false,
      UnitNumber: '',
      UnitSerialNumber: '',
      UnitName: '',
      ProfileName: '',
      Status: '',
      IsActive: '',
      DueDateTime: 0,
      CompletedOnDateTime: 0,
      CreatedDateTime: 0,
      ModifiedDateTime: 0,
      CreatedByUserId: null,
      UpdatedBy: '',
      Comments: [],
      Alarms: [],
      MarkAsComplete: false,
      MarkAsDeleted: false,
      UpdateActualEffort: false,
      Comment: ''
    },
    taskListCount: 0,
    isLoading: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    filteredResults: [],
    filterBy: {
      filter: null,
      filterStatus: null,
      filterAssignedByUser: null,
      filterCreatedByUser: null,
      filterIsServiceTask: null,
      filterUnitId: null
    },
    limit: 1000,
    currentPage: 1
  }
};

export const initialEventCodeState = {
  eventCode: {
    eventCodeList: [],
    filteredResults: [],
    selectedEventCode: {
      EventCodeId: 0,
      GuidIdentifier: '',
      Code: '',
      EventCategory: '',
      Severity: 1,
      Name: '',
      Description: '',
      GeneralGlobalSettingId: 0,
      ModelComponentPositionId: 0,
      Threshold: '',
      NextActionDetails: '',
      IsActive: true,
      IsDeleted: false
    },
    isLoading: false,
    isRedirect: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    limit: 1000,
    currentPage: 1
  }
};

export const initialModelComponentTypeState = {
  modelComponentType: {
    modelComponentTypeList: [],
    filteredResults: [],
    selectedModelComponentType: {
      ModelComponentTypeId: 0,
      GuidIdentifier: '',
      Name: '',
      Description: '',
      FirmwareType: '',
      IsActive: true,
      IsDeleted: false,
      modelSubComponentTypes: []
    },
    isLoading: false,
    isRedirect: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    limit: 1000,
    currentPage: 1
  }
};

export const initialComponentPartsState = {
  componentParts: {
    componentPartsList: [],
    filterData: {
      brands: [],
      models: [],
      componentTypes: []
    },
    selectedComponentPart: {
      ComponentPartId: 0,
      SerialNumber: '',
      Location: '',
      OrganisationId: 0,
      ApprovedComponentId: 0,
      ModelComponentPositionId: null,
      UnitId: 0,
      UnitModelId: 0,
      IsFaulty: false,
      IsActive: true,
      IsDeleted: false,
      UpdatedBy: '',
      OrganisationName: '',
      UnitSerialNumber: '',
      Brand: '',
      Model: '',
      IsConsumable: false,
      ModelComponentTypeId: 0,
      ComponentTypeName: '',
      ModelComponentPositionName: '',
      TelemetryData: [],
      TelemetryHistory: [],
      FirmwareHistory: []
    },
    componentPartsListCount: 0,
    filteredResults: [],
    isLoading: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    filterBy: {
      filter: null,
      filterBrand: null,
      filterModel: null,
      filterComponentType: null,
      filterUnit: null,
      filterUnitId: null
    },
    limit: 1000,
    currentPage: 1
  }
};

export const initialApprovedComponentState = {
  approvedComponent: {
    approvedComponentList: [],
    filteredResults: [],
    calibrationEquations: [],
    modelComponentTypes: [],
    modelSubComponentTypes: [],
    selectedApprovedComponent: {
      ApprovedComponentId: 0,
      GuidIdentifier: '',
      ModelComponentTypeId: null,
      Brand: '',
      Model: '',
      ApprovedComponentRef: '',
      Description: '',
      UnitOfMeasure: '',
      IsConsumable: false,
      HasState: false,
      IsServiceable: false,
      SupplierName: '',
      SupplierAddress: '',
      ManufacturerName: '',
      ManufacturerAddress: '',
      DatasheetURL: '',
      HasFeedback: false,
      IsActive: true,
      ApprovedSubComponentsData: [],
      showAddEditScreen: false,
      SelectedApprovedSubComponent: {
        ApprovedComponentId: 0,
        ApprovedSubComponentId: 0,
        ApprovedSubComponentName: '',
        GuidIdentifier: '',
        ModelSubComponentTypeId: '',
        CalibrationRequired: false,
        CalibrationFrequency: '',
        CalibrationEquationId: null,
        CalibrationAcceptedErrorTolerance: 0,
        Output: false,
        OutputRangeLow: null,
        OutputRangeHigh: null,
        OutputUnitOfMeasure: '',
        IsActive: true,
        isChanged: false,
        isDeleted: false
      }
    },
    isLoading: false,
    isRedirect: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    limit: 1000,
    currentPage: 1
  }
};

export const initialUnitConfigurationState = {
  unitConfiguration: {
    componentParts: {
      componentPartsList: [],
      spareComponentPartsList: [],
      selectedComponentPart: {
        ComponentPartId: 0,
        OldComponentPartId: 0,
        ModelComponentPositionId: 0,
        UnitId: 0,
        SerialNumber: '',
        OldSerialNumber: '',
        Location: '',
        ApprovedComponentId: null,
        UnitSerialNumber: '',
        UpdatedBy: '',
        IsConsumable: false,
        UpdateSerialNumber: false,
        AllocateComponentPart: false,
        ComponentTypeName: '',
        Brand: '',
        Model: '',
        ModelComponentTypeId: 0,
        ModelComponentPositionName: '',
        IsFaulty: false,
        GuidIdentifier: '',
        IsActive: true,
        IsDeleted: false
      },
      isLoading: false,
      showAddEditScreen: false,
      showBanner: false,
      isOpSuccessful: false,
      displayMessageCode: ''
    },
    globalSettings: [],
    thresholds: [],
    isGlobalSettingsLoading: false,
    isThresholdsLoading: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    limit: 1000,
    currentPage: 1
  }
};

export const initialModelTelemetryLinkState = {
  TelemetryLink: {
    TelemetryLinks: [],
    selectedTelemetryLink: {
      TelemetryLinkId: 0,
      GuidIdentifier: '',
      SourceModelComponentPositionLinkId: 0,
      TargetModelComponentPositionLinkId: 0,
      Description: '',
      TelemetryType: -1,
      UpdatedBy: '',
      SourceComponentPositionName: '',
      TargetComponentPositionName: ''
    },
    isLoading: false,
    isRedirect: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    limit: 1000,
    currentPage: 1
  }
};

export const initialUnitTagHistoryState = {
  unitTagHistory: {
    unitTagHistoryList: [],
    filterData: {
      Units: [],
      Users: []
    },
    unitTagHistoryListCount: 0,
    filteredResults: [],
    isLoading: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    filterBy: {
      filterUnitId: null,
      filterTagOutUser: null,
      filterTagOutFrom: null,
      filterTagInFrom: null,
      filterIsTaggedOut: null
    },
    offset: 0,
    limit: 1000,
    currentPage: 1
  }
};

export const initialForgotPasswordState = {
  forgotPassword: {
    isLoading: false,
    verificationSent: false,
    isOpSuccessful: false,
    showMessage: false,
    displayMessageCode: ''
  }
};

export const initialCommandSchedulerState = {
  commandScheduler: {
    commandSchedules: [],
    selectedCommandSchedule: {
      CommandSchedulerId: 0,
      GuidIdentifier: '',
      UnitId: null,
      GeneralGlobalSettingId: null,
      ScheduleFrequency: -1,
      StartDateTime: '',
      EndDateTime: '',
      UpdatedBy: '',
      CommandName: '',
      SequenceCode: ''
    },
    isLoading: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    limit: 1000,
    currentPage: 1
  }
};

export const initialUnitServiceState = {
  unitService: {
    unitServices: [],
    filteredResults: [],
    selectedUnitService: {
      UnitServiceId: 0,
      GuidIdentifier: '',
      ChecklistId: null,
      ChecklistName: '',
      ServiceDateTime: null,
      UnitServiceStatus: 'SCHEDULED',
      ChecklistUnitId: null,
      ModelVersionServicePlanId: null,
      UserId: null,
      UserName: '',
      UnitId: null,
      UnitName: '',
      WarrantyCost: '',
      WarrantyHours: '',
      NonWarrantyCost: '',
      NonWarrantyHours: '',
      Tasks: [],
      ComponentParts: [],
      UnitPartServices: []
    },
    filterBy: {
      filterUnitId: null,
      filterServiceUser: null,
      filterUnitServiceStatus: null,
      filterServiceFrom: null,
      filterServiceTo: null
    },
    filterData: {
      Units: [],
      Assignees: []
    },
    exportedResults: [],
    exportComplete: false,
    exportCompute: false,
    servicesListCount: 0,
    isLoading: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    limit: 10000,
    offset: 0,
    currentPage: 1
  }
};

export const initialDailySummaryState = {
  dailySummary: {
    search: {
      deviceId: '',
      unitSerialNumber: '',
      fromDate: 0,
      toDate: 0,
      selectAll: false,
      dataOptions: [],
      exportedResults: [],
      exportComplete: false,
      exportCompute: false
    },
    isLoading: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: ''
  }
};

export const initialUnitStatisticsState = {
  unitStatistics: {
    isTabOpen: true,
    isDashboardTabOpen: true,
    statisticTypes: [],
    statisticPeriods: STATISTIC_PERIODS,
    statisticSettings: [],
    statistics: [],
    selectedStatistics: {
      UnitStatisticSettingId: 0,
      StatisticTypeId: 0,
      UserId: 0,
      TileOrder: 1,
      FromDashboard: false,
      GuidIdentifier: '',
      UpdatedBy: 0
    },
    isLoading: false,
    showBanner: false,
    isOpSuccessful: false
  }
};

export const initialTelemetryPartsState = {
  telemetryParts: {
    telemetryParts: [],
    filteredResults: [],
    filterBy: {
      filterUnitId: null,
      filterBrand: null,
      filterModel: null,
      filterComponentTypeId: null,
      filterComponentPosition: null,
      filterTelemetryType: null,
      filterStatus: null
    },
    filterData: {
      Units: [],
      Brands: [],
      Models: [],
      ComponentTypes: [],
      Positions: []
    },
    exportedResults: [],
    exportComplete: false,
    exportCompute: false,
    telemetryPartListCount: 0,
    isLoading: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: '',
    limit: 1000,
    offset: 0,
    currentPage: 1
  }
};

export const initialNotificationsState = {
  notifications: {
    notificationTypes: [],
    notificationTypeUsers: [],
    notificationsHistory: [],
    showNotificationAlerts: [],
    notificationsList: [],
    selectedNotificationType: {
      NotificationTypeId: 0,
      Name: '',
      Description: ''
    },
    selectedNotificationTypeUser: {
      NotificationUserId: 0,
      NotificationTypeId: 0,
      UserId: 0,
      UpdatedBy: 0
    },
    isLoading: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: ''
  }
};

export const initialFAQCategoryState = {
  faqCategory: {
    faqCategories: [],
    selectedFAQCategory: {
      FAQCategoryId: 0,
      Name: '',
      Description: '',
      GuidIdentifier: '',
      UpdatedBy: '',
      IsActive: true,
      IsDeleted: false,
      questions: []
    },
    isLoading: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: ''
  }
};

export const initialFAQuestionState = {
  faQuestion: {
    faQuestions: [],
    faqCategories: [],
    selectedFAQuestion: {
      FAQuestionId: 0,
      FAQCategoryId: 0,
      Question: '',
      Answer: '',
      GuidIdentifier: '',
      UpdatedBy: '',
      IsActive: true,
      IsDeleted: false
    },
    isLoading: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: ''
  }
};

export const initialOrganisationRegionState = {
  organisationRegion: {
    organisationRegions: [],
    selectedOrganisationRegion: {
      OrganisationRegionId: 0,
      OrganisationId: 0,
      Name: '',
      Description: '',
      GuidIdentifier: '',
      UpdatedBy: '',
      IsActive: true,
      IsDeleted: false
    },
    isLoading: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: ''
  }
};

export const initialReleaseNotesState = {
  releaseNote: {
    releaseNotes: [],
    viewReleaseNotes: [],
    filteredResults: [],
    selectedReleaseNote: {
      ReleaseNoteId: 0,
      Name: '',
      VersionNo: '',
      Notes: '',
      GuidIdentifier: '',
      UpdatedBy: '',
      IsPublished: false,
      IsDeleted: false
    },
    filterBy: {
      filter: null
    },
    PerformDelete: false,
    isLoading: false,
    showBanner: false,
    isOpSuccessful: false,
    displayMessageCode: ''
  }
};

export const initialSequenceVersionState = {
  sequenceVersionManager: {
    globalSettings: [],
    sequenceVersions: [],
    filteredResults: [],
    performDelete: false,
    duplicateSequence: false,
    selectedSequenceVersion: {
      SequenceVersionId: 0,
      ModelVersionId: 0,
      VersionNo: '',
      GuidIdentifier: '',
      Name: '',
      Description: '',
      VerifyErrorCode: '',
      VerifyWarningCode: '',
      StartStateId: 0,
      IsPublished: false,
      IsActive: true,
      UpdatedBy: '',
      SequenceVersionFiles: [],
      selectedSequenceVersionFile: {
        SequenceVersionFileId: 0,
        SequenceStateId: 0,
        HasSuperState: false,
        SuperStateId: 0,
        JsonText: '',
        WaitFor: 0,
        UpdatedBy: 0
      },
      isOpSuccessful: false,
      showBanner: false
    },
    filterBy: {
      filter: null
    },
    offset: 0,
    limit: 1000,
    isLoading: false,
    isOpSuccessful: false,
    showBanner: false,
    redirect: false
  }
};

export const initialStaticDataState = {
  staticDataManager: {
    staticDataList: [],
    filteredResults: [],
    selectedStaticData: {
      UnitStaticDataId: 0,
      UnitId: 0,
      GuidIdentifier: '',
      Name: '',
      Description: '',
      PublishedByDateTime: 0,
      IsPublished: false,
      IsActive: true,
      UpdatedBy: '',
      UpdatedDateTime: 0,
      TelemetryData: [],
      SelectedUnitStaticDataTelemetry: {
        UnitStaticDataTelemetryId: 0,
        ModelComponentPositionId: 0,
        ModelComponentPositionName: '',
        IsPublishable: false,
        Value: '',
        StartDateTime: 0,
        EndDateTime: 0,
        IsDeleted: 0
      }
    },
    filter: '',
    offset: 0,
    limit: 1000,
    isLoading: false,
    isOpSuccessful: false,
    showBanner: false,
    redirect: false
  }
};
